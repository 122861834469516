import React from 'react'
import styles from './Navbar.module.css'
import logoEn from '../../images/logoEn.png'
import logoAr from '../../images/LogoAr.png'
import { useTranslation } from 'react-i18next';
import { changeAppLanguage } from '../../utils/utils';
import { ReactComponent as LogoEn } from '../../images/logoEn.svg';
import { ReactComponent as LogoAr } from '../../images/logoAr.svg';




const Navbar = () => {

  const { t, i18n } = useTranslation();
 
  

  return (
    <div className={styles.navbar}>
      {/* <img className={i18n.language==="ar"?styles.logoAr:styles.logoEn} src={i18n.language==="ar"?logoAr:logoEn} alt='logo' /> */}

      {i18n.language==="ar"?<LogoAr style={{marginRight:41}}/>:<LogoEn style={{marginLeft:41}}/>}



      <div className={i18n.language==="ar"?styles.languageAr:styles.languageEn} onClick={changeAppLanguage}>{i18n.language==="ar"?'English':'العربية'}</div>




    </div>
  )
}

export default Navbar