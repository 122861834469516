import React from 'react'
import styles from './Error.module.css'
import { useTranslation } from 'react-i18next';

const Error = ({error="Your order has not been found"}) => {

    const { t, i18n } = useTranslation();


  return (
    <div className={styles.error}>{t('Not Found')}</div>
  )
}

export default Error