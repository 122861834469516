import { useTranslation } from 'react-i18next';
import './App.css';
import Router from './router/router';
import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';



function App() {

  const { t,i18n } = useTranslation();

  useEffect(() => {
    let language = localStorage.getItem("language")

    if(language&&language!==i18n.language){
      i18n.changeLanguage(language);
    }

    if(process.env.REACT_APP_AMPLITUDE_API_KEY){

      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    }

  }, [])
  


  console.log(i18n.dir())

  document.body.dir = i18n.dir();

  return (
    <div className='App'>
   <Router/>
   </div>
  );
}

export default App;
