import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome Message": "Welcome",
      "Hero Text":"Enjoy our Tracking Service to all your orders, now at Khazenly",
      "Shipment Search":"Search orders",
      "Tracking Number":"Tracking Number",
      "Search":"Search",
      "Order Details":"Order Details",
      "Order Timeline":"Order Timeline",
      "On the way":"On the way",
      "Not Found":"No record of this tracking number can be found at this time, please check the number and try again later. For further assistance, please contact Customer Service."
    }
  },
  ar: {
    translation: {
      "Welcome Message": "اهلا",
      "Hero Text": "استمتع بخدمة التتبع للطلبات الخاصة بك, الآن مع خزنلي",
      "Shipment Search":"ابحث عن طلبك",
      "Tracking Number":"رقم التتبع",
      "Search":"ابحث",
      "Order Details":"تفاصيل الطلب",
      "Order Timeline":"الجدول الزمني للطلب",
      "On the way":"في الطريق اليك",
      "Merchant Order Number":"رقم طلب التاجر",
      "Not Found":"لا يمكن العثور على أي سجل لرقم التتبع هذا في الوقت الحالي ، يرجى التحقق من الرقم والمحاولة مرة أخرى لاحقًا. لمزيد من المساعدة ، يرجى التواصل بخدمة العملاء.",
      "New Order":"أوردر جديد",
      "Order Being Prepared":"جاري تجهيز الأوردر",
      "Order Ready":"الأوردر جاهز للشحن",
      "Order Ready - Payment Pending":"الأوردر جاهز للشحن و الدفع معلق",
      "Order Collected from Fulfilment Center":"تم شحن الأوردر من مخزن خزنلي",
      "Order Reached Sorting Center":"وصل الأوردر الى محطة الفرز",
      "Order Ready for Final Dispatch":"الأوردر جاهز للتوصيل",
      "Out for Delivery":"الأوردر خرج للتوصيل",
      "Order Delivered":"تم توصيل الأوردر",
      "Order Delivery Failed":"لم يتم توصيل الأوردر",
      "Order Rescheduled":"تم تأجيل الأوردر",
      "Returned to Fulfilment Center":"تم رجوع الأوردر للمخزن",
      "Return Receiving In Progress":"يتم استلام المرتجع في المخزن",
      "Return Completed":"تم استلام المرتجع في المخزن",
      "Order Collected from Shipper":"تم استلام الأوردر من التاجر",
      "Order Returned to Shipper":"تم تسليم الأوردر للتاجر",
      "New Return":"أوردر مرتجع جديد",
      "Out for Pickup":"خرج للاستلام",
      "Return Picked Up":"تم استلام المرتجع",
      "Return Failed":"لم يتم استلام المرتجع",
      "Return Reached Fulfilment Center":"تم رجوع الأوردر للمخزن",
      "Order Confirmed by Shipper":"تم تأكيد الأوردر من التاجر",
      "Order In-transit to Delivery Hub":"الأوردر في الطريق إلي محطة الشحن",
      "Ready for Pickup":"الأوردر جاهز للاستلام",
      "In-transit to Fulfilment Center":"الأوردر في الطريق إلى المخزن",
      "New Refund Request":"أوردر استرداد نقدي جديد",
      "Request Initiated":"تم بدأ العمل في أوردر الاسترداد النقدي",
      "In Progress":"جاري العمل",
      "Refund Complete":"تم رد النقود للعميل",
      "Delete Requested":"طلب إلغاء الأوردر",
      "Contact Success Manager":"تواصل مع الدعم",
      "Cancelled":"تم إلغاء الأوردر",
      "Damaged":"أوردر تالف",
      "Lost":"أوردر ضائع",
      "Deleted":"تم إلغاء الأوردر",
      "Voided":"تم إلغاء الأوردر",
      "Returned to Warehouse":"تم رجوع الأوردر للمخزن",
      "Returned to Shipper":"تم تسليم الأوردر للتاجر",
      "Order In-transit to Sorting Center":"الأوردر في الطريق إلي محطة الفرز",
      "Picked By Merchant":"تم استلام الأوردر من التاجر",
      "Order On Hold":"الأوردر معلق",
      "New Cash Collection":"أوردر تحصيل نقدي جديد",
      "Out for Cash Collection":"الأوردر خرج للتحصيل النقدي",
      "Cash Collected":"تم تحصيل النقود",
      "Cash Collection Failed":"لم يتم تحصيل النقود",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;