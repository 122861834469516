import React from "react";
import styles from "./OrderDetails.module.css";
import { useTranslation } from "react-i18next";
import CheckImg from "../../images/check.png";

import { ReactComponent as Calendar } from '../../images/calendar.svg';
import { ReactComponent as Buildings } from '../../images/building.svg';
import { format, parseISO } from "date-fns";
import { arSA, enUS } from "date-fns/locale";
import { ReactComponent as Check } from '../../images/check.svg';



const OrderDetails = ({order,firstTimeline}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.orderDetails}>
      <div className={styles.title}>{t("Order Details")}</div>

      <div className={styles.detailsDiv}>
        <div className={styles.img}>
          {/* <img src={CheckImg} alt="check" /> */}
          <Check/>
        </div>

        <div className={styles.details}>
          <div className={styles.orderTitle}>{order?order.Name:''}</div>

          <div className={styles.orderDetail}>
            <Buildings/>
            <div className={styles.orderDetailText}> {order?order.Account__r.Account_Business_Name__c:"[Business Name]"}</div>
          </div>

          <div className={styles.orderDetail}>
            <Calendar/>
            <div className={styles.orderDetailText}>{firstTimeline&&firstTimeline.Order_Status_Date__c?format(parseISO(firstTimeline.Order_Status_Date__c,), 'iiii, dd MMMM', {locale:i18n.language==="ar"?arSA:enUS}):''}</div>
          </div>

          <div className={styles.orderDetail}>
          <div className={styles.orderDetailText}> {t("Merchant Order Number")} <strong>#{order?order.PO_Number__c:'34536'}</strong></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
