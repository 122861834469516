import React from "react";
import styles from "./OrderTimeline.module.css";
import { useTranslation } from "react-i18next";
import OrderStatusPin from "../OrderStatusPin/OrderStatusPin";
import OrderTimelineStatus from "../OrderTimelineStatus/OrderTimelineStatus";
import { ReactComponent as Line } from "../../images/line.svg";

const OrderTimeline = ({ timeline }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.orderTimeline}>
      <div className={styles.titleDiv}>
        <div className={styles.title}>{t("Order Timeline")}</div>
        <OrderStatusPin status={timeline && timeline[0] && timeline[0].Order_Status__c?timeline[0].Order_Status__c:''} />
      </div>



      {timeline.map((entry,i) => (
        <>
          <OrderTimelineStatus greyedOut={i!==0} entry={entry} />
          {i<timeline.length-1?
          <div className={styles.line}>
            <Line />
          </div>:''}
        </>
      ))}
    </div>
  );
};

export default OrderTimeline;
