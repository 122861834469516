import React from 'react'
import styles from './Searchbar.module.css'
// import logoEn from '../../images/logoEn.png'
import { useTranslation } from 'react-i18next';
import Searchinput from '../Searchinput/Searchinput';
import Button from '../Button/Button';


const Searchbar = ({referenceNumber}) => {

    const { t, i18n } = useTranslation();

  return (
    <div className={styles.searchbar}>

    <div className={styles.title}>{t('Shipment Search')}</div>


    <Searchinput referenceNumber={referenceNumber}/>


   


    {/* <img className={i18n.language==="ar"?styles.logoAr:styles.logoEn} src={i18n.language==="ar"?logoAr:logoEn} alt='logo' />

    <div className={i18n.language==="ar"?styles.languageAr:styles.languageEn} onClick={changeAppLanguage}>{i18n.language==="ar"?'English':'العربية'}</div> */}




  </div>
  )
}

export default Searchbar